import {damsFetch} from "../app/damsFetch";
import {useEffect, useState} from "react";
import {useAuthsState} from "../auths/authsContext";

/**
 * A hook that determines if a user has write access to a project.
 *
 * Write access is determined as follows:
 * - If the user is the creator of the project, they have write access.
 * - If the project has ACLs, the user is granted write access if they have a write ACL.
 * The hook takes a project object as a parameter, and returns an object containing the boolean
 * value of the write access.
 *
 * @param {Object} props The props passed to the hook.
 * @param {Object} props.project The project object.
 *
 * @returns {Object} An object containing the boolean value of the write access.
 */
export const useUserHasWriteAccess = ({project}) => {

    const {userData} = useAuthsState();

    const [writeAccess, setWriteAccess] = useState(false);

    const getUserId = async () => {
        const json = await damsFetch("/current-user/my-dams-id/");
        return json?.id;
    };

    const hasWriteAccess = async (project) => {
        const acls = project.content?.acls;
        const userId = await getUserId();
        if (userId && userId === project.createdById) {
            setWriteAccess(true);
        } else if (acls) {
            const aclAccess =
                acls.find(a => (a.email.toLowerCase() === userData.email.toLowerCase() && a.access === "write"));
            if (aclAccess) {
                setWriteAccess(true);
            }
        }
    };

    useEffect(() => {
        if (!userData || !project) {
            return;
        }
        hasWriteAccess(project);
    }, [userData, project]);

    return {writeAccess};
};