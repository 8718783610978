import React, {useEffect, useState} from "react";
import {damsFetch} from "../app/damsFetch";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SolrStatus from "./SolrStatus";
import {clientLog} from "../clientLog";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {ReIndexButton} from "./ReIndexButton";
import LinearProgress from "@mui/material/LinearProgress";
import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

/**
 * Reindex data
 *
 * Shows the number of documents and folders in the database and
 * allows the user to start reindexing jobs for documents and folders.
 *
 * @returns {JSX.Element} The component.
 */
export const ReIndex = () => {

    const snackbarDispatch = useSnackbarDispatch();

    const [loadingStats, setLoadingStats] = useState(true);

    const [indexingState, setIndexingState] = useState({
        documents: {
            loading: undefined,
        },
        folders: {
            loading: undefined,
        }
    });

    const errorHandler = error => {
        clientLog('error', error.toString(), 'reindex');
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: 'Re-indeksering feilet',
                body: error.toString(),
                type: 'error'
            }
        });
    };

    /**
     * Fetches the number of documents or folders in the database and updates
     * the state with the result.
     *
     * @param {string} modelType The type of model to get the count for. Should
     * be "documents" or "folders".
     */
    const getModelTypeStats = async (modelType) => {
        try {
            const res = await damsFetch(`/statistics/count/${modelType}/`);
            if (res?.count > -1) {
                return res.count;
            }
        } catch (error) {
            errorHandler(error);
        }
    };

    /**
     * Gets the number of documents and folders in the database.
     *
     * The method will call `getModelTypeStats` for both "documents" and
     * "folders" and wait for the promises to resolve before returning.
     *
     * @returns {Promise<void>} A promise that resolves when the stats
     * have been fetched and the state has been updated.
     */
    const getStats = async () => {
        const documents = await getModelTypeStats('documents');
        const folders = await getModelTypeStats('folders');
        const newState = {
            ...indexingState,
            'folders': {
                ...indexingState['folders'],
                count: folders
            },
            'documents': {
                ...indexingState['documents'],
                count: documents
            }
        };
        setIndexingState(newState);
        setLoadingStats(false);
    };

    /**
     * Schedules a reindexing job for the given model type.
     *
     * The function will make a POST request to the server to start a job
     * for reindexing the given model type. The response from the server
     * will be stored in the component state.
     *
     * If the request fails, the error will be logged and a snackbar message
     * will be shown.
     *
     * @param {string} modelType The type of model to reindex. Should be either
     * "documents" or "folders".
     */
    const reindex = async (modelType) => {
        try {
            if (!modelType || modelType === '' || modelType === undefined) {
                throw new Error('modelType is not specified');
            }

            setIndexingState({
                ...indexingState,
                [modelType]: {
                    ...indexingState[modelType],
                    loading: true
                }
            });

            const res = await damsFetch(`/jobs/reindex/${modelType}/`);
            setIndexingState({
                ...indexingState,
                [modelType]: {
                    ...indexingState[modelType],
                    ...res,
                    loading: false
                }
            });
        } catch (error) {
            errorHandler(error);
        }
    };

    /**
     * Returns a JSX element to display the status of the scheduled job.
     *
     * If the job is currently being scheduled, a dialog with a loading
     * animation will be returned. If the job has been scheduled, a caption
     * with the job id will be returned.
     *
     * @param {string} modelType - The type of model for which the job was
     * scheduled. Should be either "documents" or "folders".
     * @returns {JSX.Element} A JSX element to display the status of the
     * scheduled job.
     */
    const getScheduledJobInfo = (modelType) => {
        if (indexingState[modelType]['loading']) {
            return <Dialog open={indexingState[modelType]['loading']}>
                <DialogTitle>Registrerer jobb</DialogTitle>
                <DialogContent>
                    <LinearProgress variant={"indeterminate"} color={"secondary"}/>
                </DialogContent>
            </Dialog>;
        } else if (Object.keys(indexingState[modelType]).length > 0) {
            return <Box>
                <Typography variant={"caption"}>{indexingState[modelType]['job']}</Typography>
            </Box>;
        }
    };

    /**
     * Hook used to initially load the stats.
     */
    useEffect(() => {
        if (!loadingStats) {
            return;
        }
        getStats().then();
    }, [loadingStats]);

    return <Paper
        sx={{
            margin: '16px',
            padding: '16px',
            width: '100%',
        }}
    >
        <Typography variant={"h5"}>
            Re-indekser data
        </Typography>
        {loadingStats && <LinearProgress variant={"indeterminate"} color={"secondary"}/>}
        {!loadingStats &&
            <Box>
                <Typography>
                    Det er {indexingState['documents']['count']} dokumenter
                    og {indexingState['folders']['count']} mapper i databasen.
                </Typography>

                <ReIndexButton callback={reindex}
                               documentType={'documents'}
                               label={'Re-indekser dokumenter'}/>
                {getScheduledJobInfo('documents')}

                <ReIndexButton callback={reindex}
                               documentType={'folders'}
                               label={'Re-indekser mapper'}/>
                {getScheduledJobInfo('folders')}

                <Box sx={{marginTop: '16px'}}>
                    <SolrStatus/>
                </Box>
            </Box>
        }
    </Paper>;
};
