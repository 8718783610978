/**
 * Given a parsed KulturNav project object, return the investigation ID.
 * @param investigationId
 * @returns {*|string}
 */
export const parseInvestigationId = investigationId => {
    if (Array.isArray(investigationId) && investigationId.length > 0) {
        if (Object.keys(investigationId[0]).includes('value')) {
            return investigationId[0].value;
        }
    }
    return '';
};

/**
 * Given a parsed KulturNav investigation type object, return the display value
 * for the given language if it exists, or the default ('*') if it does not.
 *
 * @param {Object} investigationType a parsed KulturNav investigation type object
 * @param {string} language the language to look up
 * @return {string} the display value for the given language, or empty string if not found
 */
export const parseInvestigationType = (investigationType, language) => {
    if (!language) {
        throw new Error('language is not specified');
    }
    if (Array.isArray(investigationType) && investigationType.length > 0) {
        const iType = investigationType[0];
        if (Object.keys(iType).includes('displayValue')) {
            if (Object.keys(iType.displayValue).includes(language)) {
                return iType.displayValue[language];
            } else if (Object.keys(iType.displayValue).includes('*')) {
                return iType.displayValue['*'];
            }
        }
    }
    return '';
};

/**
 * Given a parsed KulturNav super concept begin object, return the value if it exists.
 * @param superConceptBegin
 * @returns {*|string}
 */
export const parseSuperConceptBegin = superConceptBegin => {
    if (!Array.isArray(superConceptBegin)) {
        return '';
    }

    if (superConceptBegin.length === 0) {
        return '';
    }

    if (!Object.keys(superConceptBegin[0]).includes('value')) {
        return '';
    }

    return superConceptBegin[0].value;
};

/**
 * Given a parsed KulturNav super concept end object, return the value if it exists.
 * @param superConceptEnd
 * @returns {*|string}
 */
export const parseSuperConceptEnd = superConceptEnd => {
    if (!Array.isArray(superConceptEnd)) {
        return '';
    }

    if (superConceptEnd.length === 0) {
        return '';
    }

    if (!Object.keys(superConceptEnd[0]).includes('value')) {
        return '';
    }

    return superConceptEnd[0].value;
};

/**
 * Extracts the status of a super concept from a parsed JSON object.
 *
 * @param {Object} superConceptStatus - The parsed JSON object containing the
 *   status of the super concept.
 * @param {String} language - The language code that the status should be
 *   translated to.
 * @returns {String} The translated status of the super concept, or an empty
 *   string if the status could not be found.
 */
export const parseSuperConceptStatus = (superConceptStatus, language) => {
    if (!language) {
        throw new Error('language is not specified');
    }
    if (Array.isArray(superConceptStatus) && superConceptStatus.length > 0) {
        const scStatus = superConceptStatus[0];
        if (Object.keys(scStatus).includes('displayValue')) {
            if (Object.keys(scStatus.displayValue).includes(language)) {
                return scStatus.displayValue[language];
            } else if (Object.keys(scStatus.displayValue).includes('*')) {
                return scStatus.displayValue['*'];
            }
        }
    }
    return '';
};


/**
 * Given a parsed KulturNav project object, return the system ID and name associated with the project.
 * @param data {Object} A parsed KulturNav project object
 * @return {Object} An object with two properties: `id` and `name`, or undefined if the required keys are not found in the object.
 */
export const getSystemIdAndName = (data) => {
    const key = 'investigationInvestigatesMonumentId';
    if (!Object.keys(data).includes(key)) {
        return undefined;
    }

    if (!Array.isArray(data[key]) || data[key]?.length === 0) {
        return undefined;
    }

    const refNode = data[key][0];

    if (!Object.keys(refNode).includes('value')) {
        return undefined;
    }

    if (!Object.keys(refNode.value).includes('properties')) {
        return undefined;
    }

    if (!Object.keys(refNode.value.properties).includes('alternativeIdId')) {
        return undefined;
    }

    if (!Array.isArray(refNode.value.properties['alternativeIdId']) || refNode.value.properties['alternativeIdId'].length === 0) {
        return undefined;
    }

    if (!Object.keys(refNode.value.properties['alternativeIdId'][0]).includes('value')) {
        return undefined;
    }

    const refNodeProps = refNode.value.properties;
    const systemId = refNodeProps["alternativeIdId"][0].value;

    if (!Object.keys(refNodeProps).includes('alternativeIdSystem')) {
        return undefined;
    }

    if (!Array.isArray(refNodeProps['alternativeIdSystem']) || refNodeProps['alternativeIdSystem'].length === 0) {
        return undefined;
    }

    if (!Object.keys(refNodeProps['alternativeIdSystem'][0]).includes('value')) {
        return undefined;
    }

    const systemName = refNodeProps["alternativeIdSystem"][0].value;

    return {
        id: systemId,
        name: systemName
    };
};


/**
 * Given a parsed KulturNav project object, return the entity description in the specified language.
 * @param {Object} node - parsed KulturNav project object
 * @param {string} language - the language of the description to be returned. Must be specified.
 * @returns {string} - the entity description in the specified language, or an empty string if it could not be found
 * @throws {Error} - if language is not specified
 */
export const getDescription = (node, language) => {
    if (!node) {
        return '';
    }

    if (!language) {
        throw new Error('language is not specified');
    }

    const key = 'entityDescription';

    if (!Object.keys(node).includes(key)) {
        return '';
    }

    const refNode = node[key];

    if (!Array.isArray(refNode) || refNode.length === 0) {
        return '';
    }

    const descNode = refNode[0];
    if (!Object.keys(descNode).includes('value')) {
        return '';
    }

    if (!Object.keys(descNode.value).includes(language)) {
        return '';
    }

    return descNode.value[language];
};

/**
 * Given a parsed KulturNav project object, return the monument type.
 * @param node
 * @param language
 * @returns {string}
 */
export const getMonumentType = (node, language) => {
    if (!language) {
        throw new Error('language is not specified');
    }

    if (!node) {
        return '';
    }

    const key = 'investigationCulturalHistoryResult';

    if (!Object.keys(node).includes(key)) {
        return '';
    }

    const refNode = node[key];

    if (!Array.isArray(refNode) || refNode.length === 0) {
        return '';
    }

    if (!Object.keys(refNode[0]).includes('value')) {
        return '';
    }

    const culturalHistoryResultNodeValue = refNode[0].value;

    if (!culturalHistoryResultNodeValue) {
        return '';
    }

    if (!Object.keys(culturalHistoryResultNodeValue).includes('properties')) {
        return '';
    }

    if (!Object.keys(culturalHistoryResultNodeValue.properties).includes('culturalHistoryResultMonumentType')) {
        return '';
    }

    const monumentNode = culturalHistoryResultNodeValue.properties["culturalHistoryResultMonumentType"];

    if (!Array.isArray(monumentNode) || monumentNode.length === 0) {
        return '';
    }

    if (!Object.keys(monumentNode[0]).includes('displayValue')) {
        return '';
    }

    return monumentNode[0].displayValue[language] || '';
};


/**
 * Given a parsed KulturNav project object, return the cultural history result period.
 *
 * @param node - parsed KulturNav project object
 * @param language - the language of the period to be returned. Must be specified.
 * @returns {string} - the monument period in the specified language, or an empty string if it could not be found
 *
 * @throws {Error} - if language is not specified
 */
export const getMonumentPeriod = (node, language) => {
    if (!language) {
        throw new Error('language is not specified');
    }

    if (!node) {
        return '';
    }

    const key = 'investigationCulturalHistoryResult';

    if (!Object.keys(node).includes(key)) {
        return '';
    }

    const refNode = node[key];

    if (!Array.isArray(refNode) || refNode.length === 0) {
        return '';
    }

    if (!Object.keys(refNode[0]).includes('value')) {
        return '';
    }

    const culturalHistoryResultNodeValue = refNode[0].value;

    if (!culturalHistoryResultNodeValue) {
        return '';
    }

    if (!Object.keys(culturalHistoryResultNodeValue).includes('properties')) {
        return '';
    }

    if (!Object.keys(culturalHistoryResultNodeValue.properties).includes('culturalHistoryResultPeriod')) {
        return '';
    }

    const periodNode = culturalHistoryResultNodeValue.properties["culturalHistoryResultPeriod"];
    if (!periodNode) {
        return '';
    }

    if (!Array.isArray(periodNode) || periodNode.length === 0) {
        return '';
    }

    if (!Object.keys(periodNode[0]).includes('displayValue')) {
        return '';
    }

    if (!Object.keys(periodNode[0].displayValue).includes(language)) {
        return '';
    }

    return periodNode[0].displayValue[language];
};


/**
 * Gets the name of the dataset the project belongs to.
 * @param m
 * @returns {*|string}
 */
export const getProjectDataset = (m, language) => {
    if (!m) {
        return '';
    }

    if (!Object.keys(m).includes('properties')) {
        return '';
    }
    const {properties} = m;

    if (!Object.keys(properties).includes('entityDataset')) {
        return '';
    }

    const {entityDataset} = properties;

    if (!Array.isArray(entityDataset) || entityDataset.length === 0) {
        return '';
    }

    if (!Object.keys(entityDataset[0]).includes('displayValue')) {
        return '';
    }

    let datasetName = entityDataset[0].displayValue[language];

    if (!datasetName) {
        datasetName = entityDataset[0].displayValue['*'];
    }

    if (!datasetName) {
        datasetName = '';
    }

    return datasetName;
};


/**
 * Gets the geographical position of where the project takes place.
 * @param value
 * @returns {{lng: undefined, lat: undefined}|{lng, lat}}
 */
export const getLatLng = value => {
    let lat;
    let lng;

    if (!Object.keys(value).includes('properties')) {
        return {lat: undefined, lng: undefined}
    }

    if (!Object.keys(value.properties).includes('entityLatitude')) {
        return {lat: undefined, lng: undefined}
    }

    if (!Object.keys(value.properties).includes('entityLongitude')) {
        return {lat: undefined, lng: undefined}
    }

    const {entityLatitude, entityLongitude} = value.properties;

    if (!Array.isArray(entityLatitude) || entityLatitude.length === 0) {
        return {lat: undefined, lng: undefined}
    }

    if (!Array.isArray(entityLongitude) || entityLongitude.length === 0) {
        return {lat: undefined, lng: undefined}
    }

    lat = entityLatitude[0].value;
    lng = entityLongitude[0].value;

    return {lat: lat, lng: lng};
};