import {ADD_MESSAGE} from "./snackbar/SnackbarContext";
import {clientLog} from "./clientLog";

/**
 * Shows a snackbar message with the given title, message body, and message type.
 * @param {Object} opts - options object
 * @param {function} opts.dispatch - dispatch function from snackbar context
 * @param {string} opts.title - title of the snackbar message
 * @param {string} opts.msg - body of the snackbar message
 * @param {string} opts.msgType - type of the snackbar message, one of 'success', 'info', 'warning', or 'error'
 */
export const showSnackbar = ({dispatch, title, msg, msgType}) => {
    if (typeof dispatch === 'function') {
        dispatch({
            type: ADD_MESSAGE,
            message: {
                title: title,
                body: msg,
                type: msgType,
            },
        });
    } else {
        clientLog('warn', 'showSnackbar failed: ', title, ' ', msg, ' ', msgType);
        clientLog('error', 'dispatch is not a function', 'showsnackbar');
    }
};