import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {damsFetch} from "../app/damsFetch";
import {getHrDateAndTime} from "../utility";
import {clientLog} from "../clientLog";


let statusId = undefined;

/**
 * Fetches the status of the Solr cores, and displays it in a human-readable way.
 * The status is refreshed every 60 seconds.
 * @returns {JSX.Element}
 * @constructor
 */
const SolrStatus = () => {
    const [coreStates, setCoreStates] = useState([]);
    const [lastUpdated, setLastUpdated] = useState();
    const [solrStatusMsg, setSolrStatusMsg] = useState("");

    /**
     * Updates the `lastUpdated` state with the current human-readable date and time.
     * This is used to refresh the Solr status display every 60 seconds.
     * @param _ ignored
     */
    const setUpdated = (_) => {
        const hr = getHrDateAndTime(new Date());
        setLastUpdated(hr);
    };

    /**
     * Fetches the status of the Solr cores, and updates the component state with
     * the results.
     *
     * The status is fetched from the Solr status API, and the response is
     * processed to extract the state of each core.
     *
     * The state of each core is stored in the `coreStates` state, and the
     * `lastUpdated` state is updated with the current date and time.
     *
     * If there is an error, the error message is stored in the `solrStatusMsg`
     * state.
     */
    const getStatus = () => {
        clientLog('debug', 'fetching solr status', 'SolrStatus');
        damsFetch("/solr/admin/status/")
            .then((res) => {
                if (Object.keys(res.data).find((k) => k === "error")) {
                    setSolrStatusMsg(res.data.error.msg);
                } else {
                    const collectionName = "dams";
                    const collection = res.data.cluster.collections[collectionName];
                    const shardNames = Object.keys(collection.shards);
                    const shard = collection.shards[shardNames[0]];
                    const replicas = shard.replicas;

                    let cStates = [];
                    // eslint-disable-next-line no-unused-vars
                    for (const [k, v] of Object.entries(replicas)) { // NOSONAR
                        const coreName = v.nodeName.substr(0, v.nodeName.indexOf("."));
                        const state = v.state;
                        cStates.push({coreName: coreName, coreState: state});
                    }
                    cStates.sort((a, b) => (a.coreName > b.coreName ? 1 : -1));
                    setCoreStates(cStates);
                }
                setUpdated();
            })
            .catch((e) => {
                clientLog('error', e, 'SolrStatus');
            });
    };

    useEffect(() => {
        getStatus();
        if (!statusId) {
            statusId = setInterval(getStatus, 60000);
        }
    }, []);

    useEffect(() => {
        return function cleanup() {
            if (statusId) {
                clearInterval(statusId);
                statusId = undefined;
            }
        };
    }, []);

    return (
        <Box>
            <Typography variant={"body2"}>SOLR status</Typography>
            {coreStates.map((c) => {
                return (
                    <Box key={c.coreName}>
                        <Typography variant="body2">
                            {c.coreName}: {c.coreState}
                        </Typography>
                    </Box>
                );
            })}
            {solrStatusMsg && (
                <Box>
                    <Typography variant={"body2"}>{solrStatusMsg}</Typography>
                    <Typography variant={"caption"} color={"secondary"}>
                        Sist oppdatert: {lastUpdated}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SolrStatus;
