import React, {useEffect, useState} from "react";
import {SearchResultDetailsProvider} from "../search-result-details/SearchResultDetailsContext";
import {CLEAR_FQ, SET_QUERY, SET_ROWS, SET_SEARCHING, SET_START, useSearchDispatch,} from "../search/SearchContext";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useProjectTranslation} from "./projectContext";
import Box from "@mui/material/Box";
import {ProjectsSearchResults} from "./ProjectsSearchResults";
import {FolderSearchSettings} from "../search/FolderSearchSettings";
import {SearchSettings} from "../search/SearchSettings";
import {MuseumSelector} from "../museum/MuseumSelector";
import {useMuseumState} from "../museum/MuseumContext";

/**
 * A component that displays a search bar and a list of projects.
 * The search bar is connected to the `dams.searchResults.config` and
 * `dams.folderSearchResults.config` storages, and the list of projects is
 * connected to the `dams.searchResults` context.
 * The component also renders a `MuseumSelector` that allows the user to select
 * a museum to search for projects in.
 *
 * @returns {React.ReactElement} A React element that renders the search bar and
 * the list of projects.
 */
export const ProjectsLandingPage = () => {
    const t = useProjectTranslation();
    const searchDispatch = useSearchDispatch();

    const {museums: museumSelectedByUser} = useMuseumState();

    const [inputValue, setInputValue] = useState("");

    const searchSettings = SearchSettings('dams.searchResults.config');
    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');

    const [rows, setRows] = useState(folderSearchSettings.getNumberOfRows());
    const [page, setPage] = useState(0);


    /**
     * Triggered when the user presses the Enter key.
     * If the input field is empty, search for all documents.
     * Otherwise, search for the given query.
     * Set the start row to 0 and clear any existing filter query.
     * @param {KeyboardEvent} event - The event that triggered this action.
     */
    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            let query = inputValue;
            if (query === "") {
                query = "*";
            }
            searchDispatch({
                type: SET_START,
                start: 0,
            });
            searchDispatch({type: CLEAR_FQ});
            searchDispatch({
                type: SET_QUERY,
                query: query,
                folderContext: false,
            });
            searchDispatch({
                type: SET_SEARCHING
            });
        }
    };

    // Needed by pagination in ProjectsSearchResults:
    const handlePageChange = (_event, newPage, projects = false) => {
        const value = newPage * rows;
        setPage(newPage);
        searchDispatch({
            type: SET_START,
            start: value,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    const handleChangeRowsPerPage = (event, projects = false) => {
        const value = event.target.value;

        if (projects) {
            folderSearchSettings.setNumberOfRows(value);
            setRows(value);
        } else {
            searchSettings.setNumberOfRows(value);
            searchDispatch({
                type: SET_ROWS,
                rows: value,
            });
        }

        searchDispatch({
            type: SET_SEARCHING
        });
    };

    useEffect(() => {
        if (!museumSelectedByUser || museumSelectedByUser?.length === 0) {
            return;
        }
        let query = inputValue;
        if (query === "") {
            query = "*";
        }
        searchDispatch({
            type: SET_START,
            start: 0,
        });
        searchDispatch({
            type: SET_QUERY,
            query: query,
            folderContext: false,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    }, [museumSelectedByUser]);

    return <Box sx={{
        flexGrow: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
    }}>
        <Box sx={{
            display: "flex",
            flexDirection: {
                xs: "column-reverse",
                sm: "row",
            },
            alignItems: "center",
            justifyContent: "center",
            gap: {
                xs: '.5rem',
                md: '1rem',
            },
            marginBlock: {
                xs: '.25rem',
                sm: '.5rem',
                md: '1rem',
            },
            marginInline: '1rem',
            paddingBlock: 2,

        }}>
            <TextField
                id="dams-search"
                label={t('searchProjects', 'Søk i prosjekter')}
                type="search"
                variant="filled"
                onKeyDown={searchOnEnter}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                fullWidth={true}
                sx={{
                    flexGrow: 1,
                    maxWidth: {
                        md: '60%',
                        lg: '50%',
                        xl: '38rem',

                    },
                }}
            />
            <Box sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <Typography variant={"body2"} noWrap sx={{marginRight: {xs: ".5rem", sm: "0"}}}>
                    {t("for", "For:")}
                </Typography>
                <MuseumSelector showWithProjectAccessOnly={true}/>
            </Box>
        </Box>


        <SearchResultDetailsProvider>
            <ProjectsSearchResults handlePageChange={handlePageChange}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   page={page}/>
        </SearchResultDetailsProvider>
    </Box>
};