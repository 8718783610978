import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

/**
 * A dialog for confirming that the user wants to add or reindex a document.
 *
 * @param {Object} props Properties of the component.
 * @param {boolean} props.open Whether the dialog is open.
 * @param {string} props.title The title to display in the dialog.
 * @param {string} props.content The content of the dialog to display.
 * @param {Function} props.onConfirm Called when the user clicks the "Ok" button.
 * @param {Function} props.onCancel Called when the user clicks the "Cancel" button.
 * @returns {ReactElement} The JSX element.
 */
const ConfirmAddOrReIndexDialog = ({open, title, content, onConfirm, onCancel}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Avbryt</Button>
                <Button onClick={onConfirm} variant={"contained"} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmAddOrReIndexDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export {ConfirmAddOrReIndexDialog};