import React, {useState} from "react";
import {styled} from "@mui/material/styles";

import {getDefaultDocumentTypesConfig, useDocumentTypeState} from "../document-type/documentTypeContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import Paper from "@mui/material/Paper";

import {FolderSearchSettings} from "../search/FolderSearchSettings";
import {SearchSettings} from "../search/SearchSettings";
import {useLocation, useNavigate} from "react-router-dom";
import {createTheme, Toolbar, Typography} from "@mui/material";
import {useSearch} from "../search/useSearch";
import {damsProjectTypes} from "./damsProjectTypes";
import {SearchResultDetails} from "../search-result-details/SearchResultDetails";
import Box from "@mui/material/Box";
import {ButtonCreateNewProject} from "./ButtonCreateNewProject";
import {SET_SORT, useFoldersDispatch} from "../folder/FoldersContext";
import {SearchResultsOrderByMenu} from "../search/SearchResultsOrderByMenu";
import Divider from "@mui/material/Divider";
import {FilterSearch} from "../search/filters/FilterSearch";
import {ResultTableProvider} from "../search/ResultTableContext";
import {ResultTable} from "../search/ResultTable";
import {clientLog} from "../clientLog";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useSearchState, useSearchTranslation} from "../search/SearchContext";
import TablePagination from "@mui/material/TablePagination";
import {ThemeProvider} from "@mui/styles";
import * as locales from "@mui/material/locale";
import i18next from "i18next";


import LinearProgress from "@mui/material/LinearProgress";
import {useMuseumState} from "../museum/MuseumContext";

const PREFIX = "ProjectsSearchResults";

const classes = {
    searchResultsPaper: `${PREFIX}-searchResultsPaper`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
    [`&.${classes.searchResultsPaper}`]: {
        margin: 0,
        [theme.breakpoints.up("xl")]: {
            margin: '0 2.5rem 2.75rem 2.5rem',
            borderRadius: '.25rem',
        },
        flexGrow: '1',
        borderRadius: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
}));

/**
 * Renders the search results for projects.
 *
 * @param {Object} props - The properties object.
 * @param {function} props.handlePageChange - The function to handle page changes.
 * @param {function} props.handleChangeRowsPerPage - The function to handle row changes.
 * @param {string} props.folderId - The ID of the folder.
 * @param {number} props.page - The current page number.
 * @return {JSX.Element|null} The rendered search results or null if no models are available.
 */
export const ProjectsSearchResults = ({
                                          handlePageChange,
                                          handleChangeRowsPerPage,
                                          folderId,
                                          page
                                      }) => {

    const location = useLocation();

    const searchSettings = SearchSettings('dams.searchResults.config');
    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');
    const navigate = useNavigate();

    const t = useSearchTranslation();
    const documentTypeState = useDocumentTypeState();
    const foldersDispatch = useFoldersDispatch();

    const [locale] = useState(i18next.language === "nbNO" ? "svSE" : "enUS");

    const {museums: museumSelectedByUser} = useMuseumState();

    // const {museums} = useMuseumState();
    // Fetching the current museum from saved search config.
    const searchConfig = JSON.parse(localStorage.getItem('dams.searchResults.config'));
    const selectedMuseum = searchConfig?.selectedMuseum || museumSelectedByUser; // Fallback if no museum is selected.

    const {searching} = useSearchState();

    const snackbarDispatch = useSnackbarDispatch();

    const [models, setModels] = useState();

    const [numProjects, setNumProjects] = useState(0);

    const {
        query,
        fl,
        start,
        expand,
        facetField,
        statsField,
        fq,
        results,
        triggerSearch,
        onlyMine,
    } = useSearchState();

    let documentTypes = documentTypeState.documentTypes || getDefaultDocumentTypesConfig();
    if (location.pathname === '/all-projects/') {
        documentTypes = 'Prosjekter';
    }

    const rows = !folderId
        ? folderSearchSettings.getNumberOfRows()
        : searchSettings.getNumberOfRows();

    const sort = !folderId
        ? searchSettings.getSortCriteria()
        : folderSearchSettings.getSortCriteria();

    const criterias = {
        query: query,
        sort: `${sort}`,
        fl: fl,
        start: start,
        rows: rows,
        expand: expand,
        documentType: documentTypes,
        facetField: facetField,
        statsField: statsField,
        fq: fq,
        museums: selectedMuseum,
        triggerSearch,
        onlyMine: onlyMine,
        onlyWriteable: false
    };

    useSearch(criterias);

    /**
     * Handles the error that occurred during the project search.
     *
     * @return {void} This function does not return a value.
     */
    const handleError = () => {
        clientLog('error', JSON.stringify(results.error), 'ProjectSearchResults');
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("searchFailedErrorTitle", "Søk feilet"),
                body: t("searchFailedErrorMessage", "Har du valgt dokumenttype?"),
                type: "error",
            },
        });
    };

    /**
     * Handles the creation of a project based on the project type.
     *
     * @param {string} projectType - The type of project to create.
     * @return {void}
     */
    const handleCreateProject = (projectType) => {
        navigate(`/new-project/${projectType}`);
    };

    /**
     * A function that handles the page change event.
     *
     * @param {_event} _event - The event object.
     * @param {number} newPage - The new page number.
     * @return {void} No return value.
     */
    const handleChangePage = (_event, newPage) => {
        handlePageChange(_event, newPage, typeof (folderId === 'undefined'));
    };

    /**
     * Handles the event when the number of rows per page is changed.
     *
     * @param {Event} event - The event object representing the change.
     * @return {void} This function does not return anything.
     */
    const handleChangeRowsPerPageEvent = (event) => {
        handleChangeRowsPerPage(event, typeof (folderId) === 'undefined');
    };

    /**
     * Hook used to handle the loaded results.
     */
    useDeepCompareEffect(() => {
        if (!results) return;
        if (results.error) handleError();

        const projectModels = results.models
            .filter(f => (f.documentType === 'Folder'
                && damsProjectTypes.includes(f.folderType)));

        setModels(projectModels);

        const projectStates = results.facets.projectStates;
        if (Object.keys(projectStates)?.length > 0) {
            const active = projectStates['true'].count;
            const inactive = projectStates['false'].count

            let activeProjectsFilterState = fq.filter(f => f.key.endsWith('.active'));
            let projectsStateFilter = {};
            activeProjectsFilterState.forEach(v => {
                projectsStateFilter[v.key] = v.value.replaceAll('"', '') === 'true';
            });

            let projectCount = active + inactive;
            if (Object.keys(projectsStateFilter).length > 0) {
                projectCount = projectsStateFilter['archeologyprojectref.active']
                && projectsStateFilter['generalprojectref.active'] ? active : inactive;
            }
            setNumProjects(projectCount);
        }
    }, [results]);

    return (models?.length >= 0 && (
            <StyledPaper className={classes.searchResultsPaper} id="projects-search-results-paper">
                <SearchResultDetails/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    flexGrow: 1
                }}> {/* Replaces MUI Grid, for reduced complexity */}
                    <Box sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }}> {/* The results area */}
                        <Toolbar sx={{
                            display: 'flex',
                            flexShrink: 1,
                            flexWrap: 'wrap',
                            gap: '.25rem',
                            alignItems: 'center',

                            "&.MuiToolbar-root": {
                                paddingLeft: 2,
                                paddingRight: 1,
                                minHeight: 'auto',
                            }
                        }}>
                            <Typography
                                flexGrow={'1'}
                                variant="h5"
                                component="h2"
                                id={"search-results-title"}
                                pr={'.25rem'}
                            >
                                {t('headingProjects', 'Prosjekter')}
                            </Typography>


                            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                                <SearchResultsOrderByMenu
                                    sort={sort}
                                    t={t}
                                    selectHandler={(value) => {
                                        foldersDispatch({
                                            type: SET_SORT,
                                            sort: value,
                                        });
                                    }}
                                />
                            </Box>

                            <ThemeProvider
                                theme={createTheme({}, locales[locale])}
                            >
                                <TablePagination
                                    sx={{
                                        minHeight: {
                                            xs: "auto",
                                            md: '4rem',
                                        },
                                        display: 'flex',
                                        alignItems: 'center',
                                        "& .MuiTablePagination-toolbar": {
                                            paddingLeft: {
                                                xs: 0,
                                                md: .5,
                                            },
                                            paddingRight: 0,
                                        },
                                        "& .MuiTablePagination-input": {
                                            marginLeft: 0,
                                            marginRight: 0.5,
                                        },
                                        "& div.MuiTablePagination-actions": {
                                            marginLeft: .5,
                                        }
                                    }}
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={numProjects}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPageEvent}
                                    rowsPerPage={
                                        folderSearchSettings.getNumberOfRows()
                                    }
                                    labelRowsPerPage={t("rowsPerPage", "Vis")}
                                    labelDisplayedRows={({from, to, count}) =>
                                        t("labelDisplayedRows", "{{from}}-{{to}} av {{count}}", {
                                            from: from,
                                            to: to,
                                            count: count,
                                        })
                                    }
                                />
                            </ThemeProvider>

                            <Box paddingBlock={'.5rem'}>
                                <ButtonCreateNewProject createProjectCallback={handleCreateProject}/>
                            </Box>
                        </Toolbar>
                        <Divider sx={{margin: 0}}/>

                        <Box id="results-area" sx={{
                            padding: 0,
                            margin: 0,
                            overflow: 'hidden', // As tempting as it is to turn on scroll here, do it inside the results component. Because it might have another toolbar, etc.
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'stretch'
                        }}>
                            {(!models || models?.length === 0)
                                && searching
                                && <LinearProgress variant={"indeterminate"} sx={{width: '100%', minWidth: '100%'}}
                                                   color={"secondary"}/>}

                            {models?.length > 0 && <ResultTableProvider>
                                <ResultTable folderId={folderId} dataRows={models}/>
                            </ResultTableProvider>}
                        </Box>
                    </Box>

                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{margin: 0}}
                        />
                        {/* Flex child for the filters: */}
                        <Box sx={{
                            padding: 0,
                            margin: 0,
                            minWidth: {
                                sm: '25rem',
                                xl: '29rem'
                            },
                            maxWidth: {
                                sm: '25rem',
                                xl: '29rem',
                            },
                            flexBasis: {
                                sm: '25rem',
                                xl: '29rem',
                            },
                            flexShrink: 0,
                            display: 'flex'
                        }}>
                            <FilterSearch/>
                        </Box>
                    </Box>
                </Box>
            </StyledPaper>)
    );
};