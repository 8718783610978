import {damsFetch} from "./app/damsFetch";


/**
 * Fetches the status of the specified DMS object(s) from the DMSF service.
 *
 * @param {string} collectionId - The ID of the collection containing the object.
 * @param {string|string[]} dmsId - The ID (or IDs) of the object(s) to fetch the status of.
 * @param {function} callback - Function to be called with the status data.
 *   If `dmsId` is an array, the callback will be called with an array of status objects.
 *   If `dmsId` is a string, the callback will be called with a single status object.
 * @param {function} errorCallback - Function to be called if the request fails.
 */
export const getObjectStatus = (collectionId, dmsId, callback, errorCallback) => {
    if (!collectionId) {
        throw new Error('Invalid collection ID');
    }

    if (!dmsId) {
        throw new Error('Invalid DMS ID');
    }

    damsFetch('/dms/object-status/',
        {
            method: 'POST',
            body: JSON.stringify({
                collectionId: collectionId,
                mediaId: dmsId
            })
        })
        .then(data => {
            if (Array.isArray(dmsId)) {
                callback(data);
            } else {
                callback(data[0]);
            }
        })
        .catch(error => {
            errorCallback(error)
        });
};