import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {useDocumentTranslation} from "../../documents/documentContext";
import {InputLabel, Select, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {damsFetch} from "../../app/damsFetch";
import {clientLog} from "../../clientLog";
import {useSnackbarDispatch} from "../../snackbar/SnackbarContext";
import {showSnackbar} from "../../showSnackbar";
import {ConfirmAddOrReIndexDialog} from "./ConfirmAddOrReIndexDialog";

/**
 * A component for adding a single document to the index or reindexing an existing one.
 *
 * It allows the administrator to select the document type and specify the unique id
 * of the document. The component will then ask for confirmation before adding the document
 * to the index or reindexing it, if it already exists.
 *
 * @returns {ReactElement} The JSX element.
 */
export const AddOrReIndexSingle = () => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogContent, setConfirmDialogContent] = useState('');
    const [documentType, setDocumentType] = useState('documents');
    const [uniqueId, setUniqueId] = useState(undefined);

    const snackbarDispatch = useSnackbarDispatch();

    const t = useDocumentTranslation();

    /**
     * Displays a snackbar message with the title "Ukjent feil",
     * message "Det oppstod en ukjent feil" and message type "error".
     */
    const showErrorSnackbar = () => {
        showSnackbar({
            dispatch: snackbarDispatch,
            title: 'Ukjent feil',
            msg: 'Det oppstod en ukjent feil',
            msgType: 'error'
        });
    };

    /**
     * Sends a request to the server to reindex a document of the specified type
     * with the specified unique id. (Adding it if it does not already exist.)
     *
     * If the request is successful, it will show a snackbar message with the title
     * "Ferdig" and a message indicating that the document was successfully reindexed.
     * If the request fails, it will show a snackbar message with the title "Ukjent feil"
     * and a message indicating that the request failed.
     *
     * @async
     */
    const reIndex = async () => {
        const url = `/index/${documentType}/${uniqueId}`;
        try {
            const res = await damsFetch(url, {
                method: 'PUT',
            });
            if (res && res.title) {
                showSnackbar({
                    dispatch: snackbarDispatch,
                    title: 'Ferdig',
                    msg: `Dokument ${uniqueId} (${res.title}) er ferdig behandlet.`,
                    msgType: 'success'
                });
            } else {
                showErrorSnackbar();
            }
        } catch (error) {
            clientLog('error', error.toString(), 'reindexsingledocument');
            showErrorSnackbar();
        }
    };

    /**
     * Handles the change event of the document type select field.
     *
     * When the select field is changed, it sets the document type state to the
     * value of the selected option.
     *
     * @param {React.SyntheticEvent} event The event.
     */
    const handleDocumentTypeChange = (event) => {
        setDocumentType(event.target['value']);
    };

    /**
     * Handles the change event of the unique id text field.
     *
     * When the text field is changed, it sets the unique id state to the
     * value of the input field.
     *
     * @param {React.SyntheticEvent} event The event.
     */
    const handleUniqueIdChange = (event) => {
        setUniqueId(event.target['value']);
    };

    /**
     * Handles the "Fortsett" button click.
     *
     * When the button is clicked, a confirmation dialog is shown with the title
     * "Legg til i indeks/ re-indekser" and a message asking whether the user is
     * sure they want to reindex the document with the unique id and type
     * selected in the form. If the user clicks "Ok", the `reIndex` function is
     * called.
     */
    const handleContinueClick = async () => {
        const hrDocumentType = documentType === 'documents' ? 'Dokument' : 'Folder';
        setConfirmDialogTitle('Legg til i indeks/ re-indekser');
        setConfirmDialogContent(
            <Box>
                Er du sikker på at du vil legge til / re-indeksere dokumentet med
                DAMS ID: {uniqueId} av typen {hrDocumentType}?
            </Box>);
        setShowConfirmDialog(true);
    };

    /**
     * Handles the "Ok" button click in the confirmation dialog.
     *
     * When the button is clicked, the `reIndex` function is called to
     * reindex the document. After the function has finished, the
     * confirmation dialog is hidden.
     */
    const handleConfirm = async () => {
        await reIndex();
        setShowConfirmDialog(false);
    };

    /**
     * Handles the "Cancel" button click in the confirmation dialog.
     *
     * When the button is clicked, the confirmation dialog is hidden.
     */
    const handleCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <>
            <ConfirmAddOrReIndexDialog
                open={showConfirmDialog}
                title={confirmDialogTitle}
                content={confirmDialogContent}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            <Paper sx={{margin: '16px', padding: '16px', width: '1000%'}}>
                <Box sx={{paddingBottom: '32px'}}>
                    <Typography variant={"h3"}
                                gutterBottom>{t('addDocumentToIndex', 'Legg til dokument i indeks')}</Typography>
                    <Typography variant={"body1"} gutterBottom>
                        {
                            t('addDocumentToIndexHelp',
                                'For å registrere et dokument manuelt i indeksen, velg dokumenttype ' +
                                'og legg inn dokumentets unike ID/DAMS ID.')
                        }
                    </Typography>
                </Box>

                <Stack direction={"row"}>
                    <FormControl>
                        <InputLabel id='select-documenttype-label'>Dokumenttype</InputLabel>
                        <Select
                            variant={"filled"}
                            labelId='select-documenttype-label'
                            id='select-documenttype'
                            value={documentType}
                            label='Dokumenttype'
                            onChange={handleDocumentTypeChange}
                        >
                            <MenuItem value={'documents'}>Dokument</MenuItem>
                            <MenuItem value={'folders'}>Folder</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField id="document-id"
                               label="DAMS ID"
                               variant="outlined"
                               onChange={handleUniqueIdChange}
                               sx={{marginLeft: '16px'}}/>

                    <Button variant="contained"
                            sx={{
                                marginLeft: '16px'
                            }}
                            onClick={handleContinueClick}
                    >
                        Fortsett
                    </Button>
                </Stack>
            </Paper>
        </>
    );
};