import {PromisePool} from "@supercharge/promise-pool";
import {extractMediaMeta} from "./dmsSecurity";
import {SET_METADATA_MAPPING_PROGRESS} from "../documents/documentContext";
import {clientLog} from "../clientLog";

/**
 * Function for fetching metadata for a list of DMS IDs.
 *
 * The function will retry failed requests up to 5 times with a 200ms delay
 * between each attempt.
 *
 * @param {Object} options - Collection of options.
 * @param {function} options.docDispatch - Function for dispatching actions.
 * @param {string} options.collectionId - ID of the collection.
 * @param {Array<string>} options.dmsIds - List of DMS IDs.
 *
 * @return {Object} Object with a single method, `run`.
 * @property {function} run - Method for running the metadata fetching.
 *   It will return a Promise that resolves to a list of objects with the
 *   following properties:
 *     - `dmsId`: The DMS ID of the object.
 *     - `data`: The metadata of the object as an object.
 */
export const getFilesMetadata = ({docDispatch, collectionId, dmsIds}) => {

    const componentName = 'getFilesMetadata';
    let retryCounter = 5;

    /**
     * Retrieves metadata for a given DMS ID.
     *
     * If the request fails with a 500 error, the error is re-thrown.
     * Otherwise, the request is retried up to 5 times with a 200ms delay
     * between each attempt.
     *
     * @param {string} dmsId - DMS ID of the object.
     *
     * @return {Promise<Object>} Promise that resolves to an object with the
     *   following properties:
     *     - `dmsId`: The DMS ID of the object.
     *     - `data`: The metadata of the object as an object.
     */
    const getMetadata = async (dmsId) => {
        try {
            const data = await extractMediaMeta(collectionId, dmsId)
            return {
                dmsId: dmsId,
                data: data[0] // For some reason the data is returned as an array.
            };
        } catch (e) {
            if (e === 500) {
                throw e;
            } else {
                return retry(dmsId);
            }
        }
    };

    /**
     * Retry fetching metadata for a given DMS ID.
     *
     * @param {string} dmsId - DMS ID of the object.
     *
     * @return {Promise<Object>} Promise that resolves to an object with the
     *   following properties:
     *     - `dmsId`: The DMS ID of the object.
     *     - `data`: The metadata of the object as an object.
     *
     *   If all retries have failed, the Promise is rejected with an Error.
     */
    const retry = (dmsId) => {
        retryCounter--;
        if (retryCounter > 0) {
            clientLog('warn',
                `metadata fetching failed for ${dmsId} - remaining retries: ${retryCounter}`,
                componentName);
            setTimeout(() => {
                return getMetadata(dmsId);
            }, 200);
        } else {
            clientLog('error',
                `metadata fetching failed for ${dmsId} - even after retrying`,
                componentName);
            throw new Error();
        }
    };

    /**
     * Fetches metadata for a list of DMS IDs.
     *
     * @param {string} collectionId - ID of the collection.
     * @param {Array<string>} dmsIds - List of DMS IDs.
     *
     * @return {Promise<Array<Object>>} Promise that resolves to an array of
     *   objects, each with the following properties:
     *     - `dmsId`: The DMS ID of the object.
     *     - `data`: The metadata of the object as an object.
     *
     *   If the fetching fails or the `collectionId` or `dmsIds` is empty, the
     *   Promise is resolved with an empty array.
     */
    const run = async () => {
        if (!collectionId || dmsIds.length === 0) {
            return [];
        }
        return await PromisePool
            .withConcurrency(4)
            .for(dmsIds)
            .onTaskFinished((user, pool) => {
                // Update current stage progress
                const percentage = parseInt(pool.processedPercentage());
                docDispatch({type: SET_METADATA_MAPPING_PROGRESS, value: percentage});
            })
            .process(async (dmsId) => {
                return await getMetadata(dmsId);
            });
    };

    return {
        run: run
    };
};