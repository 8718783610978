import {useProjectTranslation} from "./projectContext";
import {CLEAR_FQ, SET_QUERY, SET_SEARCHING, UPDATE_FQ, useSearchDispatch} from "../search/SearchContext";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {SearchResultDetailsProvider} from "../search-result-details/SearchResultDetailsContext";
import {DocumentTypeProvider} from "../document-type/documentTypeContext";

/**
 * ProjectViewBase component is the base component for rendering project views.
 *
 * @param {Object} props - The properties object.
 * @param {React.ComponentType} props.Root - The root component for the project view.
 * @param {Object} props.classes - The CSS classes for styling.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 * @return {React.ReactElement} The rendered ProjectViewBase component.
 */
export const ProjectViewBase = ({Root, classes, children}) => {
    const t = useProjectTranslation();

    const searchDispatch = useSearchDispatch();

    const [inputValue, setInputValue] = useState("");

    const {searchString, projectId} = useParams();

    // Set project content search criterias.
    const setCriterias = (inValue, query) => {
        if (inValue) {
            setInputValue(inValue);
        }
        searchDispatch({
            type: UPDATE_FQ,
            fq: {
                key: "folder_ids",
                value: projectId,
            },
        });
        searchDispatch({
            type: SET_QUERY,
            query: query,
            folderContext: true,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    // Execute search when user pushes ENTER on the keyboard.
    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            let query = inputValue;
            if (query === "") {
                query = "*";
            }
            searchDispatch({type: CLEAR_FQ});
            setCriterias(undefined, query);
        }
    };

    // Execute search for the project content.
    useDeepCompareEffect(() => {
        if (!searchString || searchString === "*") {
            setCriterias("", "*");
        } else if (searchString) {
            setCriterias(searchString, searchString);
        }
    }, [searchString, setCriterias]);

    return (<Root className={'landingPage'}>
        <DocumentTypeProvider>
            <Box className={'search'}>
                <Box className={classes.searchAndType}>
                    <TextField
                        fullWidth={true}
                        id="dams-search"
                        label={t('searchInProject', 'Søk i prosjektet')}
                        type="search"
                        variant="filled"
                        onKeyDown={searchOnEnter}
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                    />
                </Box>
            </Box>
            <SearchResultDetailsProvider>
                <Box className={'searchDetailsContainer'}>
                    {children}
                </Box>
            </SearchResultDetailsProvider>
        </DocumentTypeProvider>
    </Root>);
};