import React, {useEffect, useState} from "react";
import {SET_FILES_FAILED_MAPPING, useDocumentDispatch, useDocumentTranslation} from "../../documents/documentContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {useMetadataMappingUtility} from "../../files/useMetadataMappingUtility";
import {getFilesMetadata} from "../../dms/getFilesMetadata";
import {ProgressDialogContent} from "./ProgressDialogContent";
import {ConfirmExtractionContent} from "./ConfirmExtractionContent";
import {OneOrMoreFailedContent} from "./OneOrMoreFailedContent";
import {AllFailedContent} from "./AllFailedContent";
import {filesReadyForMetadataExtraction, keyToValue} from "./mappingfunctions";
import {getBrowserLocale} from "../../utility";
import {Stack} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const PREFIX = "MappingDialog";

const classes = {
    toolbar: `${PREFIX}-toolbar`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.toolbar}`]: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));

export const MappingDialog = ({
                                  files,
                                  collectionId,
                                  mapping,
                                  onHide,
                                  onFinished,
                              }) => {
    const currentLocale = getBrowserLocale(true);
    const norwegianUser = ['no', 'nn', 'nb'].some(value => currentLocale.includes(currentLocale));

    const t = useDocumentTranslation();

    const [dmsIds, setDmsIds] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [finished, setFinished] = useState(false);

    const [preProcessing, setPreProcessing] = useState(false);

    const [createPerson] = useMetadataMappingUtility();

    const [filesMeta, setFilesMeta] = useState([]);

    const [allFailed, setAllFailed] = useState(false);
    const [oneOrMoreFailed, setOneOrMoreFailed] = useState(false);

    const docDispatch = useDocumentDispatch();

    /**
     *  Callback triggered after adding persons and producers to DAMS.
     *  {
     *    "ben-karpinski-2fIewAP4dOw-unsplash.jpg": {
     *    "persons": [
     *      {
     *        "referenceType": "person",
     *        "_action": "put",
     *        "collectionId": 4,
     *        "reference": {
     *          "uniqueId": "36d73ea9-ba3e-4e50-916f-cbf0f325c70c",
     *          "locale": "no",
     *          "_action": "put",
     *          "title": "APPL",
     *          "status": "published",
     *          "content": {
     *            "name": "APPL",
     *             "yearOfBirth": "",
     *             "yearOfDeath": "",
     *             "nameAndTitle": "APPL"
     *           }
     *         }
     *       }
     *      ],
     *      "remarks": "0134BRxNxttq.jpg",
     *      "title": "ben-karpinski-2fIewAP4dOw-unsplash.jpg"
     *    }
     *  }
     *
     * @param data
     */
    const addPersonsAndProducersCallback = data => {
        const obj = {};
        data.map(d => {
            if (typeof (d) !== 'undefined') {
                const k = Object.keys(d)[0];
                if (isNaN(parseInt(k))) {
                    obj[k] = d[k];
                }
            }
        });
        onFinished(data);
        setFinished(true);
    };

    /**
     * Adds persons and producers to DAMS, if data is extracted from the file as specified in metadata mapper config.
     */
    const addPersonsAndProducers = (mappedFiles) => {
        // Adding persons
        const personPromises = mappedFiles.map(f => createPerson(collectionId, f));
        Promise.all(personPromises).then(result => {
            addPersonsAndProducersCallback(result);
        });
    };


    /**
     * Hook used to get the DMS ID for each added file.
     */
    useDeepCompareEffect(() => {
        if (files.length) {
            const dmsIds = files.map((file) => (file.dmsId));
            setDmsIds(dmsIds);
        }
    }, [files]);

    /**
     * Hook used to run the routine that fetches metadata from the files,
     * after confirmation from the user.
     */
    useEffect(() => {
        if (!dmsIds) {
            return;
        }

        async function getMetadata() {
            const data = await getFilesMetadata({
                docDispatch: docDispatch,
                collectionId: collectionId,
                dmsIds: dmsIds
            }).run();

            if (!data) {
                return;
            }

            if (data.results) {
                setFilesMeta(data.results);
            }

            const numErrors = data.errors?.length || 0;

            // Store the DMS IDs of the failed objects.
            const failedDmsIds = data.errors.map(e => e.item);
            if (failedDmsIds.length > 0) {
                docDispatch({type: SET_FILES_FAILED_MAPPING, files: failedDmsIds});
            }

            setAllFailed(numErrors === files.length);
            setOneOrMoreFailed(numErrors > 0);

            if (!data.results) {
                setAllFailed(true);
            }
        }

        if (confirm) {
            setPreProcessing(true);
            filesReadyForMetadataExtraction(collectionId, dmsIds)
                .then(() => {
                    setPreProcessing(false);
                    getMetadata().then();
                });
        }
    }, [confirm, dmsIds]);

    /**
     * Hook used to update the mappedFiles object with EXIF-data extracted from each file.
     */
    useDeepCompareEffect(() => {
        function mapMetadata() {
            return new Promise(resolve => {
                const promises = [];

                const mappedFiles = files.filter(f => filesMeta.find(ff => ff.dmsId === f.dmsId));

                for (let i = 0, max = mappedFiles.length; i < max; i++) {
                    const file = mappedFiles[i];
                    promises.push({[file.title]: keyToValue(norwegianUser, mapping, filesMeta.find(f => f.dmsId === file.dmsId).data)})
                }

                Promise.allSettled(promises).then(results => {
                    const data = results.filter(r => r.status === 'fulfilled');
                    data.forEach(d => {
                        const titleKey = Object.keys(d.value)[0];
                        const fileIx = mappedFiles.findIndex(m => m.title === titleKey);
                        let f = mappedFiles[fileIx];
                        // eslint-disable-next-line no-unused-vars
                        for (const [k, v] of Object.entries(d.value)) { // NOSONAR
                            f = {...f, ...v};
                        }
                        mappedFiles[fileIx] = f;
                    });
                    return resolve(mappedFiles);
                });
            });
        }

        if (filesMeta.length === 0) {
            return;
        }

        // Add extracted metadata to each file.
        mapMetadata().then(mapped => {
            addPersonsAndProducers(mapped);
        });
    }, [filesMeta]);

    return (
        <Root sx={{
            maxWidth: '100%',
            width: '500px'
        }}>
            <Typography variant={"h5"} component={"h2"}>
                {t("fileMetaHeader", "Filmetadata")}
            </Typography>

            {/* Show progress */}
            {
                confirm
                && preProcessing
                && <Stack direction={"column"}
                          alignItems={"center"}
                          justifyContent={"center"}>
                    <Typography mt={2} mb={2}>
                        {t('processingFiles', 'Vent, prosesserer opplastede filer')}
                    </Typography>
                    <LinearProgress variant={"indeterminate"}
                                    sx={{width: '90%'}}/>
                </Stack>
            }
            {(confirm && !finished && !oneOrMoreFailed && !allFailed) &&
                <ProgressDialogContent t={t}/>
            }

            {/* Confirm metadata extraction */}
            {(!confirm && !finished && !oneOrMoreFailed && !allFailed) &&
                <ConfirmExtractionContent t={t}
                                          classes={classes}
                                          cancelCallback={() => {
                                              onHide(false);
                                          }}
                                          confirmCallback={() => {
                                              setConfirm(true);
                                          }}/>
            }

            {/* Mapping finished and/or one or more failed */}
            {(finished || oneOrMoreFailed) &&
                <OneOrMoreFailedContent
                    t={t}
                    classes={classes}
                    clickCallback={() => {
                        onHide(true);
                    }}/>
            }

            {/* Mapping finished - all failed */}
            {(finished && allFailed) &&
                <AllFailedContent t={t}
                                  classes={classes} c
                                  clickCallback={() => {
                                      onHide(true)
                                  }}/>
            }
        </Root>
    );
};
