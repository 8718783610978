import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import PropTypes from "prop-types";

/**
 * A button for reindexing documents or folders.
 *
 * @param {function} callback The method to call when the button is clicked. The
 * method should take the document type as an argument.
 * @param {string} documentType The type of document to reindex. Should be either
 * "documents" or "folders".
 * @param {string} label The label to show on the button.
 * @returns {JSX.Element} The component.
 */
const ReIndexButton = ({callback, documentType, label}) => {

    const [disabled, setDisabled] = useState(false);

    /**
     * Handles the button click event.
     *
     * This method is used as the `onClick` handler for the button. It sets the
     * `disabled` state to true, and calls the `callback` method with the
     * `documentType` as an argument.
     */
    const handleOnClick = () => {
        setDisabled(true);
        callback(documentType);
    };

    return <Box
        sx={{marginTop: 2}}
        key={`button-container-${documentType}`}>
        <Button variant={"contained"}
                onClick={handleOnClick}
                disabled={disabled}>
            {label}
        </Button>
    </Box>;
};

ReIndexButton.propTypes = {
    callback: PropTypes.func.isRequired,
    documentType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export {ReIndexButton};